export const FirebaseConfig = {
	"projectId": "ezrid3z-26",
	"appId": "1:844873302690:web:6cd3dd0fe25b613525cdfb",
	"databaseURL": "https://ezrid3z-26-default-rtdb.firebaseio.com",
	"storageBucket": "ezrid3z-26.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDmUDGn2qLs1Y0AY7hzI4fsxJnvwshc6Jg",
	"authDomain": "ezrid3z-26.firebaseapp.com",
	"messagingSenderId": "844873302690",
	"measurementId": "G-DQ3K9Z7ZD6"
};